import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import config from "./config";
import Amplify from "aws-amplify";
import * as serviceWorker from "./serviceWorker";

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "enrichHer-mvp",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
});

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
