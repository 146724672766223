import React, { Component } from "react";
import { API } from "aws-amplify";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
export class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      loadingComplete: 'false'
    };
    this.getResultsData = this.getResultsData.bind(this);
  }
/*Looks up data with email key - RN */
  getResultsData() {
    var userEmail = this.props.match.params.id;

    API.get("enrichHer-mvp", "getuserdata", {
      queryStringParameters: {
        email: userEmail
      }

    })
      .then(response => {
        console.log(response);
        /*added the loadingResult update to the set state call*/
        this.setState({
          approvedLenders: response.approvedLenders,
          userName: response.rawData.hidden.first_name,
          loadingComplete:'true'
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    setTimeout(this.getResultsData, 3000);

    /* using this data for a variable instead of text to type*/
    
   /* this.setState({loadingResult: ' is complete'});*/
   this.setState({
    loadingComplete:'true'
  });
 
  }

  render() {
    return (
      <div className="resultsPage">
        <div className="resultsHeaderWrapper">
          <img
            src={require(`../Assets/enrichherLogo.png`)}
            alt="EnrichHER Logo"
          />
        </div>

        {/* If the username exists in the results page, do the following -RN */
        this.state.userName !== "" ? 
        (
          <div>
            <div className="headerWrapper">
              <h1 className="lenderUserName">
                <strong>
                Let Us {" "}
                  <span className="highlightedText">
                  Fund
                  </span>{" "}
                  You
                </strong>
              </h1>

              <h2 className="lenderUserName">About EnrichHER’s All Rise Factory Program.{" "}  </h2>

              <p className="eligibilityText">The purpose of our unique, one-month All Rise Factory Program is to help businesses achieve their next level goals. The companies in the previous All Rise Factory cohorts have generated between $150 thousand and $4 Million in revenue and on average, have been in business for three years. Each EnrichHER cohort is extremely competitive. We received 3,000 applications within the first six months of launching in 2019. Should you be selected to participate in the next All Rise Cohort, an EnrichHER team member will contact you and share next steps. Due to the competitive nature of this program, we can not guarantee participation, but we encourage you to enjoy all of the additional perks that your membership offers, starting with your complimentary EnrichHER Society account! </p>

              <p className="eligibilityText"> In addition to being considered for the next cohort, below you will other financing opportunities offered through EnrichHER.com partners. </p>
                {/*
              <p className="scheduleText">
              
                Please schedule a time below to meet with our success team so
                that we may help you select the best funding options for your
                business.
                
              </p>
              <div className="scheduleWrapper">
                {/*
                <iframe
                  src="https://calendly.com/enrichher-customer-success/enrichher-funding-approval?primary_color=fc3381"
                  title="Calendly"
                  frameBorder="0"
                ></iframe>
                
              </div>
              */}
              <h2 className="lenderUserName">
                {this.state.userName}, check out these additional financing opportunities through EnrichHER lending partners:
              </h2>
            </div>
     
        )
        
        {
            /*this.state.loadingResult == " in progress" ? (
              <div class="lenderCategories">
              <p className="scheduleText">
                  This is temporary text to see if this works well. I'm adding code to see if this works.
                </p>
                  <a href="https://enrichher.com">
                    <button>Learn More About Other Options</button>
                  </a>
                </div>
            ): null*/

        }
        
         {  /* annie commons code 
          this.state.approvedLenders.kabbage === false &&
            this.state.approvedLenders.streetShares === false &&
            this.state.approvedLenders.enrichHer === false &&
            this.state.approvedLenders.seekCapital === false &&
            this.state.approvedLenders.credibilityCapital === false ? 
            (
              <div class="lenderCategories">
                <a href="https://enrichher.com">
                  <button>Learn More About Other Options</button>
                </a>
              </div>
            ) : null */
        }
            <Container className="lenderCategories">
              {this.state.approvedLenders.lendistry ? (
                <Row>
                  <Col lg={true}>
                    <img
                      src={require("../Assets/Lendistry.png")}
                      alt="Street Shares Logo"
                    />
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">6% - 24%</p>
                      <p className="infoText">Estimated APR</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">$150k</p>
                      <p className="infoText">Minimum Yearly Biz Revenue</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">640</p>
                      <p className="infoText">Minimum Credit Score</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <a href="https://calendly.com/enrichher-customer-success/enrichher-funding-approval">
                        <button>Schedule Appointment</button>
                      </a>
                      <p className="infoText">
                        {" "}
                        Schedule to chat about Lendistry
                      </p>
                    </div>
                  </Col>
                </Row>
              ) : null}
              {this.state.approvedLenders.credibilityCapital ? (
                <Row>
                  <Col lg={true}>
                    <img
                      src={require("../Assets/credibilityCapitalLogo.png")}
                      alt="Credibility Capital Logo"
                    />
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">8% - 20%</p>
                      <p className="infoText">Estimated APR</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">$150k</p>
                      <p className="infoText">Minimum Yearly Biz Revenue</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">680</p>
                      <p className="infoText">Minimum Credit Score</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <a href="https://credibilitycapital.com/enrichher?utm_source=enrichher">
                        <button>Apply Now</button>
                      </a>
                      <p className="infoText">
                        {" "}
                        🔐On Crediblity Capital's Secure Website
                      </p>
                    </div>
                  </Col>
                </Row>
              ) : null}
              {this.state.approvedLenders.seekCapital ? (
                <Row>
                  <Col lg={true}>
                    <img
                      src={require("../Assets/seekBusinessLogo.png")}
                      alt="Seek Business Logo"
                    />
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">8% - 30%</p>
                      <p className="infoText">Estimated APR</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">$0k</p>
                      <p className="infoText">Minimum Yearly Biz Revenue</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">680</p>
                      <p className="infoText">Minimum Credit Score</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <a href="https://www.seekcapital.com/lp/peh">
                        <button>Apply Now</button>
                      </a>
                      <p className="infoText">
                        {" "}
                        🔐On Seek Capital's Secure Website
                      </p>
                    </div>
                  </Col>
                </Row>
              ) : null}

              {/*this.state.approvedLenders.kabbage ? (
                <Row>
                  <Col lg={true} className="enrichherLogo">
                    <img
                      src={require("../Assets/kabbageLogo.png")}
                      alt="Kabbage Logo"
                    />
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">8% - 99%</p>
                      <p className="infoText">Estimated APR</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">$75k</p>
                      <p className="infoText">Minimum Yearly Biz Revenue</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">550</p>
                      <p className="infoText">Minimum Credit Score</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <a href="https://app.kabbage.com/signup/create_account?refid=nrp_10205">
                        <button>Apply Now</button>
                      </a>
                      <p className="infoText">🔐 On Kabbage's Secure Website</p>
                    </div>
                  </Col>
                </Row>
              ) : null*/}
              {/* {this.state.approvedLenders.streetShares ? (
                <Row>
                  <Col lg={true}>
                    <img
                      src={require("../Assets/streetSharesLogo.png")}
                      alt="Street Shares Logo"
                    />
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">8% - 35%</p>
                      <p className="infoText">Estimated APR</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">$75k</p>
                      <p className="infoText">Minimum Yearly Biz Revenue</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">640</p>
                      <p className="infoText">Minimum Credit Score</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <a href="https://go.streetshares.com/enrichher">
                        <button>Apply Now</button>
                      </a>
                      <p className="infoText">
                        {" "}
                        🔐 On Street Share's Secure Website
                      </p>
                    </div>
                  </Col>
                </Row>
              ) : null} */}

              {/*this.state.approvedLenders.enrichHer ? (
                <Row>
                  <Col lg={true}>
                    <img
                      src={require("../Assets/enrichherLogo.png")}
                      alt="EnrichHer Logo"
                    />
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">8-20%</p>
                      <p className="infoText">Estimated APR</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">$50k+</p>
                      <p className="infoText">Minimum Yearly Biz Revenue</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <p className="dataText">600</p>
                      <p className="infoText">Minimum Credit Score</p>
                    </div>
                  </Col>
                  <Col lg={true}>
                    <div>
                      <a href="http://funding.ienrichher.com/">
                        <button>Apply Now</button>
                      </a>
                      <p className="infoText">
                        🔐 On EnrichHer's Secure Website
                      </p>
                    </div>
                  </Col>
                </Row>
              ) : null*/}
            </Container>
          </div>
        ) : this.state.userName !== "" && this.state.loadingComplete !== "false" ?
        (
          <div className="loadingText">No results found...</div>
        ):
        (
         /* <div className="loadingText"> Loading your results is  {this.state.loadingComplete}
          
         <div className="loadingText"> Loading your results...</div> 
          <p>Loading your results...</p>
                    </div> */

          <div>
            <div className="headerWrapper">
              <h1 className="lenderUserName">
                <strong>
                  Your EnrichHER Capital Application {" "}
                  {" "}
                </strong>
              </h1>
              <p className="eligibilityText">
              Thank you for applying to receive funding as a participant in our All Rise Factory Cohort.{" "}  </p>

              <p className="eligibilityText">Based on the information you provided, you, unfortunately, do not meet our program requirements.  Should your  information change, you are welcome to reapply through EnrichHER Society. {" "}  </p>

              <p className="eligibilityText">In order to best support you and  help you achieve your business financing goals, EnrichHER invites you to attend a FREE Business Financing Workshop. This workshop is a great resource to gain deeper understanding of your business finances and assists you in achieving your next level business goals.  </p>

              <p className="eligibilityText">Click the button below to sign up to attend our Business Financing Workshop.

              </p>
            </div>

          <Container>
          <Row>

                    <Col lg={true}>
                    <div className="masterclassWrapper">
                
                        </div>
                    </Col>
                    <Col lg={true}>
                        <div className="masterclassWrapper">
                            <img
                            src={require("../Assets/business_financing.png")}
                            alt="Business Financing Masterclass"
                            />
                        </div>
                        <div className="masterclassWrapper">
                      <a href="https://go.enrichher.com/webinar-registration-bfmc-v1/?utm_source=fundingapp&utm_medium=landing&utmcampaign=mar">
                        <button>Learn more</button>
                      </a>
                      <p className="infoText">
                        {" "}
                        🔐On EnrichHER's Secure Website
                      </p>
                    </div>
                    </Col>
                    <Col lg={true}>
                    <div className="masterclassWrapper">
                    </div>
                    </Col>
            </Row>
</Container>
          </div>
        )
        }
        <div className="pressSection">
          <h4>
            <strong>As Seen In </strong>
          </h4>
          <div className="editorial">
          <img
            src={require("../Assets/editorial-logos-bw_small.png")}
            alt="Editorial Logos"
          />
          </div>
          {/* <Container>
            <Row>
              <Col lg={true}>
                <img src={require("../Assets/forbes.png")} alt="Forbes Logo" />
              </Col>
              <Col lg={true}>
                <img src={require("../Assets/sxsw.png")} alt="Forbes Logo" />
              </Col>
              <Col lg={true}>
                <img
                  src={require("../Assets/usatoday.png")}
                  alt="Forbes Logo"
                />
              </Col>
              <Col lg={true}>
                <img
                  src={require("../Assets/fastcompany.png")}
                  alt="Forbes Logo"
                />
              </Col>
              <Col lg={true}>
                <img src={require("../Assets/women20.png")} alt="Forbes Logo" />
              </Col>
            </Row>
          </Container> */}
        </div>
        <div className="aboutWrapper">
          <h2>Let Us Fund You</h2>
          <h4><strong>About EnrichHER</strong></h4>
          <p>
            <a href="https://enrichher.com/">
              <strong>EnrichHER</strong>
            </a>{" "}
            (Techstars ‘19) is a lending platform
            that{" "}
            <span className="highlightedText">
              <strong>
                provides up to $250k in affordable and non-dilutive capital.
              </strong>
            </span>{" "}
           EnrichHER is a deeply engaged network of women-led business owners. Our rapidly growing community provides tactical support and guidance, while we provide unique access to business financing.{" "}
          </p>
          <p>
            Our network has engaged with over 23,000 people through our online community and in-person activations. Through this ecosystem, EnrichHER provides a variety of financial resources that can help women-led businesses grow including small business loans, training, debit & credit cards, and affiliates & partnerships.{" "}
          </p>
          <p>
           In just over six months since the launch of our SEC/FINRA approved lending platform, EnrichHER received 3,000 applications from women-led businesses across 47 states. We’ve helped women led businesses access over $3m in capital and will grow that figure to $57m in 2020.
          </p>
          <p>
            EnrichHER has been featured in{" "}
            <span className="highlightedText">
              <strong>
                <a href="https://enrichher.com/press">
                USA Today, Fast Company, Yahoo Finance, Black Enterprise, and{" "}
                more.</a>
              </strong>
            </span>
          </p>
        </div>
      </div>
    );
  }
}
