import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Results } from "./Components/ResultsPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Route path="/:id" component={Results} />
      </Router>
    </div>
  );
}

export default App;
